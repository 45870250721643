import React from 'react';
import styled from 'styled-components';
import { SecondaryHero } from '@latitude/hero';
import { Metadata } from '@latitude/metadata';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Accordion } from '@latitude/accordion';
import { Heading5, Heading6 } from '@latitude/heading';
import { List, ListItem } from '@latitude/list';
import {
  COLOR,
  MARGIN,
  PADDING,
  ALIGN,
  JUSTIFY_CONTENT
} from '@latitude/core/utils/constants';
import { Box } from '@/components/Box/Box';
import Text from '../components/Text/Text';
import Layout from '../components/layout';
import { CardGroup, CardGroupItem } from '../components/CardGroup/CardGroup';
import { BREAKPOINT } from '../utils/constants';

const TargetMarketDeterminations = props => {
  return (
    <Layout location={props.location}>
      <main className="navigation-spacer">
        <Metadata
          title="Target Market Determinations | Latitude Financial"
          description="If you're in market for a new car or another vehicle, a Car Loan from Latitude can help you achieve your goals. Apply today!"
          canonical="https://www.latitudefinancial.com.au/target-market-determinations/"
        />
        <Box
          css={`
            @media (min-width: ${BREAKPOINT.SM}) {
              white-space: nowrap;
            }
          `}
        >
          <SecondaryHero heading="Target Market Determinations" />
        </Box>
      </main>
      <Box.Section
        backgroundColor={COLOR.GREY6}
        isResponsive
        margin={`${MARGIN.MAUTO}`}
      >
        <CardGroup>
          <CardGroupItem col={1}>
            <InfoBox title="What is a Target Market Determination?">
              <Text
                css={`
                  // Overrides added below to account for UX request (Ref: CEPW-1205)
                  padding-bottom: 15px;
                `}
              >
                A Target Market Determination is a document created by Latitude
                Financial Services which describes:
              </Text>
              <List>
                <ListItem>
                  the class of customers who comprise of the product's target
                  market.
                </ListItem>
                <ListItem>
                  any conditions and restrictions on how the product can be
                  distributed to customers.
                </ListItem>
                <ListItem>
                  events or circumstances that will require Latitude to review
                  the Target Market Determination for a financial product.
                </ListItem>
              </List>
            </InfoBox>
          </CardGroupItem>
        </CardGroup>
      </Box.Section>
      <AnalyticsLocationProvider location="Our products">
        <Box
          css={`
            // Overrides added below to account for UX request (Ref: CEPW-1205)
            [class^='Box__StyledComponent'] {
              padding-top: 5px;
            }
          `}
        >
          <Box.Section backgroundColor={COLOR.GREY6}>
            <SectionHeading>Our products</SectionHeading>
            <Accordion
              fgColor={COLOR.BLACK}
              titleBgColor={COLOR.BLUE_SKY}
              contentBgColor="#eff7ff"
              items={[
                {
                  id: 'loans',
                  title: 'Loans',
                  content: (
                    <div className="py-4 pl-4 pr-4">
                      <Heading6 color={COLOR.BLACK} marginBottom={MARGIN.M16}>
                        Current
                      </Heading6>
                      <List>
                        <SectionListItem>
                          Latitude Fixed Rate Personal Loans
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2024/personal-loan-fixed-20240601.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'personal-loans-fixed' }}
                            data-trackid="personal-loans-fixed"
                          >
                            Latitude Fixed Rate Personal Loans TMD (Effective 1
                            June 2024)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          Latitude Variable Rate Personal Loans
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2024/personal-loan-variable-20240601.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'personal-loans-variable'
                            }}
                            data-trackid="personal-loans-variable"
                          >
                            Latitude Variable Rate Personal Loans TMD (Effective
                            1 June 2024)
                          </SectionLink>
                        </SectionListItem>

                        <SectionListItem>
                          Latitude Fixed Rate Personal Loans &mdash; Loans2Go
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2025/personal-loan-fixed-20250207.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'personal-loans-fixed' }}
                            data-trackid="personal-loans-fixed"
                          >
                            Latitude Fixed Rate Personal Loans TMD (effective 07
                            Feb 2025)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          Latitude Variable Rate Personal Loans &mdash; Loans2Go
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2025/personal-loan-variable-20250207.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'personal-loans-variable'
                            }}
                            data-trackid="personal-loans-variable"
                          >
                            Latitude Variable Rate Personal Loans TMD (effective 07
                              Feb 2025)
                          </SectionLink>
                        </SectionListItem>

                        <SectionListItem>
                          Latitude Fixed Rate Motor Loans - Broker Only
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/motor-loans/2024/motor-loans-fixed-20241002.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'motor-loans-fixed' }}
                            data-trackid="motor-loans-fixed"
                          >
                            Latitude Fixed Rate Motor Loans TMD (Effective 2
                              October 2024)
                          </SectionLink>
                        </SectionListItem>
                        <SectionLastListItem>
                          Latitude Variable Rate Motor Loans - Broker Only
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/motor-loans/2024/motor-loans-variable-20241002.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'motor-loans-variable' }}
                            data-trackid="motor-loans-variable"
                          >
                            Latitude Variable Rate Motor Loans TMD (Effective 2
                            October 2024)
                          </SectionLink>
                        </SectionLastListItem>
                      </List>
                      <Heading6
                        color={COLOR.BLACK}
                        marginBottom={MARGIN.M16}
                        marginTop={MARGIN.M32}
                      >
                        Archived
                      </Heading6>
                      <List>
       
                      <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2024/personal-loan-fixed-20240513.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'personal-loans-fixed' }}
                            data-trackid="personal-loans-fixed"
                          >
                            Latitude Fixed Rate Personal Loans TMD (effective
                              13 May 2024)
                          </SectionLink>
                        </SectionListItem>       

                        <SectionListItem>
                          <SectionLink
                           href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2024/personal-loan-variable-20240513.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'personal-loans-variable'
                            }}
                            data-trackid="personal-loans-variable"
                          >
                            Latitude Variable Rate Personal Loans TMD (effective
                            13 May 2024)
                          </SectionLink>
                        </SectionListItem>


                      <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/motor-loans/2024/motor-loans-fixed-20240601.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'motor-loans-fixed' }}
                            data-trackid="motor-loans-fixed"
                          >
                            Latitude Fixed Rate Motor Loans TMD (Effective 1
                            June 2024)
                          </SectionLink>
                        </SectionListItem>

                      <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/motor-loans/2024/motor-loans-variable-20240601.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'motor-loans-variable' }}
                            data-trackid="motor-loans-variable"
                          >
                            Latitude Variable Rate Motor Loans TMD (Effective 1
                            June 2024)
                          </SectionLink>
                      </SectionListItem>



                      <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2024/personal-loan-fixed-20240319.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'personal-loans-fixed' }}
                            data-trackid="personal-loans-fixed"
                          >
                            Latitude Fixed Rate Personal Loans TMD (Effective 19
                            March 2024)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2024/personal-loan-variable-20240319.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'personal-loans-variable'
                            }}
                            data-trackid="personal-loans-variable"
                          >
                            Latitude Variable Rate Personal Loans TMD (Effective
                            19 March 2024)
                          </SectionLink>
                        </SectionListItem>

                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/motor-loans/2023/fixed-june.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'motor-loans-fixed' }}
                            data-trackid="motor-loans-fixed"
                          >
                            Latitude Fixed Rate Motor Loans TMD (Effective 20
                            June 2023)
                          </SectionLink>
                        </SectionListItem>

                      <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/motor-loans/2023/variable-june.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'motor-loans-variable' }}
                            data-trackid="motor-loans-variable"
                          >
                            Latitude Variable Rate Motor Loans TMD (Effective 20
                            June 2023)
                          </SectionLink>
                      </SectionListItem>

                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2023/fixed-june.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'personal-loans-fixed' }}
                            data-trackid="personal-loans-fixed"
                          >
                            Latitude Fixed Rate Personal Loans TMD (Effective 1
                            June 2023)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2023/variable-june.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'personal-loans-variable'
                            }}
                            data-trackid="personal-loans-variable"
                          >
                            Latitude Variable Rate Personal Loans TMD (Effective
                            1 June 2023)
                          </SectionLink>
                        </SectionListItem>

                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2022/fixed-june.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'personal-loans-fixed' }}
                            data-trackid="personal-loans-fixed"
                          >
                            Latitude Fixed Rate Personal Loans TMD (Effective 1
                            June 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2022/variable-june.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'personal-loans-variable'
                            }}
                            data-trackid="personal-loans-variable"
                          >
                            Latitude Variable Rate Personal Loans TMD (Effective
                            1 June 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2022/fixed-may.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'personal-loans-fixed-may2022-archived'
                            }}
                            data-trackid="personal-loans-fixed-may2022-archived"
                          >
                            Latitude Fixed Rate Personal Loans TMD (Effective 10
                            May 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2022/variable-may.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'personal-loans-variable-may2022-archived'
                            }}
                            data-trackid="personal-loans-variable-may2022-archived"
                          >
                            Latitude Variable Rate Personal Loans TMD (Effective
                            10 May 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans/2021/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'personal-loans-dec-archived'
                            }}
                            data-trackid="personal-loans-dec-archived"
                          >
                            Latitude Personal Loans TMD (Effective 9 December
                            2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/personal-loans.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'personal-loans-archived'
                            }}
                            data-trackid="personal-loans-archived"
                          >
                            Latitude Personal Loans TMD (Effective 5 October
                            2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/better-start-personal-loans/2021/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'better-start-personal-loans-dec-archived'
                            }}
                            data-trackid="better-start-personal-loans-dec-archived"
                          >
                            Latitude Better Start Personal Loans TMD (Effective
                            9 December 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/better-start-personal-loans.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'better-start-personal-loans-archived'
                            }}
                            data-trackid="better-start-personal-loans-archived"
                          >
                            Latitude Better Start Personal Loans TMD (Effective
                            5 October 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/motor-loans/2022/june.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'motor-loans-june2022-archived'
                            }}
                            data-trackid="motor-loans-june2022-archived"
                          >
                            Latitude Fixed Rate Motor Loans TMD (Effective 4
                            July 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/motor-loans/2022/july.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'motor-loans-fixed-july2022-archived'
                            }}
                            data-trackid="motor-loans-fixed-july2022-archived"
                          >
                            Latitude Variable Rate Motor Loans TMD (Effective 19
                            August 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/motor-loans/2022/aug.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'motor-loans-variable-aug2022-archived'
                            }}
                            data-trackid="motor-loans-variable-aug2022-archived"
                          >
                            Latitude Motor Loans TMD (Effective 1 June 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/motor-loans/2021/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'motor-loans-dec2021-archived'
                            }}
                            data-trackid="motor-loans-dec2021-archived"
                          >
                            Latitude Motor Loans TMD (Effective 9 December 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/motor-loans.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'motor-loans-archived' }}
                            data-trackid="motor-loans-archived"
                          >
                            Latitude Motor Loans TMD (Effective 5 October 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionLastListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/loans/symple-loans/2021/oct.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'symple-loans-archived' }}
                            data-trackid="symple-loans-archived"
                          >
                            Symple Loans TMD (Effective 5 October 2021)
                          </SectionLink>
                        </SectionLastListItem>
                      </List>
                    </div>
                  )
                },
                {
                  id: 'credit-cards',
                  title: 'Credit Cards',
                  content: (
                    <div className="py-4 pl-4 pr-4">
                      <List>
                        <Heading6 color={COLOR.BLACK} marginBottom={MARGIN.M16}>
                          Current
                        </Heading6>
                        <SectionListItem>
                          Latitude GO Mastercard
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/go-mastercard/2024/latitude-gomastercard-tmd-202412.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gomc' }}
                            data-trackid="gomc"
                          >
                            Latitude GO Mastercard TMD (effective 11 Dec 2024)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          Latitude Gem Visa
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/gem-visa/2024/latitude-gemvisa-tmd-202412.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gem-visa' }}
                            data-trackid="gem-visa"
                          >
                            Latitude Gem Visa TMD (effective 11 Dec 2024)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          Latitude CreditLine
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/creditline/2024/latitude-creditline-tmd-202410.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'creditline'
                            }}
                            data-trackid="creditline"
                          >
                            Latitude CreditLine TMD (effective 31 Oct 2024)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          Latitude 28&deg; Global Platinum Mastercard
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/28degrees-global-mastercard/2024/latitude-28degrees-tmd-202408.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: '28d' }}
                            data-trackid="28d"
                          >
                            Latitude 28&deg; Global Platinum Mastercard TMD
                            (effective 7 August 2024)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          Latitude David Jones Card
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/david-jones/2024/Premiere-Card/April.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'David Jones' }}
                            data-trackid="David Jones"
                          >
                            Latitude David Jones Premiere Card TMD (effective 15
                            April 2024)
                          </SectionLink>
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/david-jones/2024/Prestige-Card/April.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'David Jones' }}
                            data-trackid="David Jones"
                          >
                            Latitude David Jones Prestige Card TMD (effective 15
                            April 2024)
                          </SectionLink>
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/david-jones/2024/Premiere-Card/May.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'David Jones' }}
                            data-trackid="David Jones"
                          >
                            Latitude David Jones Premiere credit card for
                            customers transferring from existing David Jones
                            American Express card (effective 28 May 2024)
                          </SectionLink>
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/david-jones/2024/Prestige-Card/May.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'David Jones' }}
                            data-trackid="David Jones"
                          >
                            Latitude David Jones Prestige credit card for
                            customers transferring from existing David Jones
                            American Express Platinum card (effective 28 May
                            2024)
                          </SectionLink>
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/david-jones/2024/Icon-Card/May.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'David Jones' }}
                            data-trackid="David Jones"
                          >
                            Latitude David Jones Icon credit card card for
                            customers transferring from existing David Jones
                            Storecard (effective 28 May 2024)
                          </SectionLink>
                        </SectionListItem>
                        <SectionLastListItem>
                          Latitude Low Rate Mastercard
                          <br />
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/low-rate/2024/latitude-lowratemastercard-tmd-202411.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gomc' }}
                            data-trackid="gomc"
                          >
                            Latitude Low Rate Mastercard TMD (effective 20 Nov 2024)
                          </SectionLink>
                        </SectionLastListItem>
                      </List>
                      <List>
                        <Heading6
                          color={COLOR.BLACK}
                          marginBottom={MARGIN.M16}
                          marginTop={MARGIN.M32}
                        >
                          Archived
                        </Heading6>
                        
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/go-mastercard/2024/latitude-gomastercard-tmd-202405.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gomc' }}
                            data-trackid="gomc"
                          >
                            Latitude GO Mastercard TMD (effective 15 May 2024)
                          </SectionLink>
                        </SectionListItem>

                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/gem-visa/2024/latitude-gemvisa-tmd-202405.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gem-visa' }}
                            data-trackid="gem-visa"
                          >
                            Latitude Gem Visa TMD (effective 15 May 2024)
                          </SectionLink>
                        </SectionListItem>
                        
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/creditline/2024/latitude-creditline-tmd-202405.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'creditline'
                            }}
                            data-trackid="creditline"
                          >
                            Latitude CreditLine TMD (effective 15 May 2024)
                          </SectionLink>
                        </SectionListItem>

                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/28degrees-global-mastercard/2024/latitude-28degrees-tmd-202406.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: '28d' }}
                            data-trackid="28d"
                          >
                            Latitude 28&deg; Global Platinum Mastercard TMD
                            (effective 7 June 2024)
                          </SectionLink>
                        </SectionListItem>

                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/28degrees-global-mastercard/2024/latitude-28degrees-tmd-202405.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: '28d' }}
                            data-trackid="28d"
                          >
                            Latitude 28&deg; Global Platinum Mastercard TMD
                            (effective 15 May 2024)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/go-mastercard/2022/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gomc' }}
                            data-trackid="gomc"
                          >
                            Latitude GO Mastercard TMD (effective 9 December
                            2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/gem-visa/2022/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gem-visa' }}
                            data-trackid="gem-visa"
                          >
                            Latitude Gem Visa TMD (effective 9 December 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/creditline/2022/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'creditline'
                            }}
                            data-trackid="creditline"
                          >
                            Latitude CreditLine TMD (effective 9 December 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/28degrees-global-mastercard/2023/July.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: '28d' }}
                            data-trackid="28d"
                          >
                            Latitude 28&deg; Global Platinum Mastercard TMD
                            (effective 5 July 2023)
                          </SectionLink>
                        </SectionListItem>

                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/david-jones/2024/Premiere-Card/march.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'David Jones' }}
                            data-trackid="David Jones"
                          >
                            Latitude David Jones Premiere Card TMD (effective 12
                            March 2024)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/david-jones/2024/Prestige-Card/march.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'David Jones' }}
                            data-trackid="David Jones"
                          >
                            Latitude David Jones Prestige Card TMD (effective 12
                            March 2024)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/28degrees-global-mastercard/2023/Mar.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: '28d' }}
                            data-trackid="28d"
                          >
                            Latitude 28&deg; Global Platinum Mastercard TMD
                            (effective 8 March 2023)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/28degrees-global-mastercard/2022/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: '28d' }}
                            data-trackid="28d"
                          >
                            Latitude 28&deg; Global Platinum Mastercard TMD
                            (effective 9 December 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/go-mastercard/2021/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gomc' }}
                            data-trackid="gomc"
                          >
                            Latitude GO Mastercard TMD (effective 9 December
                            2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/gem-visa/2021/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gem-visa' }}
                            data-trackid="gem-visa"
                          >
                            Latitude Gem Visa TMD (effective 9 December 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/creditline/2021/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'creditline'
                            }}
                            data-trackid="creditline"
                          >
                            Latitude CreditLine TMD (effective 9 December 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/28degrees-global-mastercard/2021/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: '28d' }}
                            data-trackid="28d"
                          >
                            Latitude 28&deg; Global Platinum Mastercard TMD
                            (effective 9 December 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/go-mastercard.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gomc-archived' }}
                            data-trackid="gomc-archived"
                          >
                            Latitude GO Mastercard TMD (effective 5 October
                            2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/gem-visa.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'gem-visa-archived' }}
                            data-trackid="gem-visa-archived"
                          >
                            Latitude Gem Visa TMD (effective 5 October 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/creditline.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'creditline-archived'
                            }}
                            data-trackid="creditline-archived"
                          >
                            Latitude CreditLine TMD (effective 5 October 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionLastListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/credit-cards/28degrees-global-mastercard.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: '28d-archived' }}
                            data-trackid="28d-archived"
                          >
                            Latitude 28&deg; Global Platinum Mastercard TMD
                            (effective 5 October 2021)
                          </SectionLink>
                        </SectionLastListItem>
                      </List>
                    </div>
                  )
                },
                {
                  id: 'buy-now-pay-later',
                  title: 'Buy Now Pay Later',
                  content: (
                    <div className="py-4 pl-4 pr-4">
                      <Heading6
                        color={COLOR.BLACK}
                        marginBottom={MARGIN.M16}
                        marginTop={MARGIN.M32}
                      >
                        Archived
                      </Heading6>
                      <List>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/bnpl/latitudepay/2022/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'lpay' }}
                            data-trackid="lpay"
                          >
                            LatitudePay TMD (Effective 9 December 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/bnpl/latitudepay-plus/2022/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'lpay-plus'
                            }}
                            data-trackid="lpay-plus"
                          >
                            LatitudePay Plus TMD (Effective 9 December 2022)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/bnpl/latitudepay/2021/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'lpay' }}
                            data-trackid="lpay"
                          >
                            LatitudePay TMD (Effective 9 December 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/bnpl/latitudepay-plus/2021/Dec.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'lpay-plus'
                            }}
                            data-trackid="lpay-plus"
                          >
                            LatitudePay Plus TMD (Effective 9 December 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/bnpl/latitudepay.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{ label: 'lpay-archived' }}
                            data-trackid="lpay-archived"
                          >
                            LatitudePay TMD (Effective 5 October 2021)
                          </SectionLink>
                        </SectionListItem>
                        <SectionLastListItem>
                          <SectionLink
                            href="https://assets.latitudefinancial.com/ddo/tmd/bnpl/latitudepay-plus.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            trackEventData={{
                              label: 'lpay-plus-archived'
                            }}
                            data-trackid="lpay-plus-archived"
                          >
                            LatitudePay Plus TMD (Effective 5 October 2021)
                          </SectionLink>
                        </SectionLastListItem>
                      </List>
                    </div>
                  )
                }
              ]}
            />
          </Box.Section>
        </Box>
      </AnalyticsLocationProvider>
    </Layout>
  );
};

const SectionHeading = styled.h4`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  margin: 0 0 20px;
  color: ${COLOR.BLACK};
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  font-family: Montserrat, sans-serif;
`;

const SectionListItem = styled.li`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  font-weight: 500;
  padding-bottom: 20px;
`;

const SectionLastListItem = styled.li`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  font-weight: 500;
`;

const SectionLink = styled.a`
  // Overrides added below to account for UX request (Ref: CEPW-1205)
  font-weight: 300;
`;

const InfoBox = ({ title, children }) => (
  <Box
    backgroundColor={COLOR.WHITE}
    padding={`${PADDING.P32}`}
    max-width="1100px"
    height="100%"
    align={ALIGN.LEFT}
    justifyContent={JUSTIFY_CONTENT.LEFT}
  >
    <Title color={COLOR.BLACK} align={ALIGN.LEFT}>
      {title}
    </Title>
    {children}
  </Box>
);

const Title = styled(Heading5)`
  font-size: 24px;
  margin-bottom: ${MARGIN.M24};
`;

export default TargetMarketDeterminations;
